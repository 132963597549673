;((document) => {
  var honeypots = document.querySelectorAll(
    '#contact_details, label[for=contact_details], #user_details, label[for=user_details]'
  )

  honeypots.forEach(function (honeypot) {
    honeypot.style.position = 'absolute'
    honeypot.style.opacity = 0
    honeypot.setAttribute('aria-hidden', 'true')
  })
})(document)
