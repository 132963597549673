import SimpleMDE from 'simplemde'
;((document) => {
  const TEXTAREA_SELECTOR = '.markdown-textarea'

  const textareas = document.querySelectorAll(TEXTAREA_SELECTOR)

  if (textareas.length) {
    textareas.forEach(function (textarea) {
      var simplemde = new SimpleMDE({
        element: textarea,
        autoDownloadFontAwesome: false,
        status: false,
      })
    })
  }
})(document)
