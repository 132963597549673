;((document) => {
  const TOOLTIP_CLASS = 'tip'
  const TOOLTIP_BUTTON_CLASS = 'tip-button'
  const TOOLTIP_CONTENT_CLASS = 'tip-content'
  const HIDDEN_CLASS = 'is-hidden'
  const ARIA_HIDDEN_ATTR = 'aria-hidden'
  const ICON_CLASS = 'icon'

  const ARIA_LABEL_ATTR = 'aria-label'
  const ARIA_LABEL_VISIBLE = 'Hide tip'
  const ARIA_LABEL_INVISIBLE = 'Show tip'

  // Toggle visibility of an element
  function toggleContentVisibility(content, icon) {
    if (content.classList.contains(HIDDEN_CLASS)) {
      content.setAttribute(ARIA_HIDDEN_ATTR, false)
      content.classList.remove(HIDDEN_CLASS)

      icon.setAttribute(ARIA_LABEL_ATTR, ARIA_LABEL_VISIBLE)
    } else {
      content.setAttribute(ARIA_HIDDEN_ATTR, true)
      content.classList.add(HIDDEN_CLASS)

      icon.setAttribute(ARIA_LABEL_ATTR, ARIA_LABEL_INVISIBLE)
    }
  }

  // bind event listeners
  document.querySelectorAll(`.${TOOLTIP_CLASS}`).forEach((tip) => {
    const button = tip.querySelector(`.${TOOLTIP_BUTTON_CLASS}`)
    const content = tip.querySelector(`.${TOOLTIP_CONTENT_CLASS}`)
    const icon = tip.querySelector(`.${ICON_CLASS}`)

    button.addEventListener('click', (e) => {
      toggleContentVisibility(content, icon)
    })
  })
})(document)
