import axios from 'axios'
;((document) => {
  const POSTCODE_LOOKUP_SELECTOR = '.postcode-lookup'
  const POSTCODE_SELECTOR = '[data-lookup="postcode"]'
  const TOWN_SELECTOR = '[data-autofill="town"]'
  const COUNTY_SELECTOR = '[data-autofill="county"]'

  const postcodeLookup = document.querySelector(POSTCODE_LOOKUP_SELECTOR)
  const postcode = document.querySelector(POSTCODE_SELECTOR)
  const town = document.querySelector(TOWN_SELECTOR)
  const county = document.querySelector(COUNTY_SELECTOR)

  if (postcodeLookup === null) return

  let autofillAddress = (address) => {
    town.value = address.town
    county.value = address.county
  }

  let getAddress = (url) => {
    const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}
    axios.defaults.headers.common['X-CSRF-Token'] = token.content

    axios
      .get(url)
      .then(function (response) {
        let address = response.data.address
        autofillAddress(address)
      })
      .catch(function (error) {
        alert('Sorry, we were unable to find your address. Please enter it manually below.')
      })
  }

  let init = () => {
    postcodeLookup.classList.remove('is-hidden')

    postcodeLookup.addEventListener('click', function (e) {
      e.preventDefault()
      let url = e.target.getAttribute('href') + '?postcode=' + postcode.value
      getAddress(url)
    })
  }

  init()
})(document)
