;(() => {
  let init = () => {
    document.querySelectorAll('[data-visibility-parent]').forEach((element) => {
      addFieldEvents(element)
    })
  }

  let addFieldEvents = (element) => {
    let parentElement = document.querySelector('[name="' + element.dataset.visibilityParent + '"]')
    let parentVisibilityValue = element.dataset.visibilityValue

    if (!parentElement) return

    switch (parentElement.tagName) {
      case 'SELECT':
        parentSelectEvents(element, parentElement, parentVisibilityValue, (bool) =>
          setVisibility(element, bool)
        )

        break
      case 'INPUT':
        parentRadioEvents(element, parentVisibilityValue, (bool) => setVisibility(element, bool))
        break
      default:
        console.log('unsupported form field')
    }
  }

  let parentSelectEvents = (element, parentElement, visibilityValue, callback) => {
    let optionActive = (parentElement, visibilityValue, callback) => {
      parentElement
        .querySelectorAll('option[value="' + visibilityValue + '"]')
        .forEach((option) => {
          if (option.selected) {
            callback(true)
          } else {
            callback(false)
          }
        })
    }

    parentElement.addEventListener('change', (e) =>
      optionActive(e.target, visibilityValue, (bool) => setVisibility(element, bool))
    )
    optionActive(parentElement, visibilityValue, (bool) => setVisibility(element, bool))
  }

  let parentRadioEvents = (element, visibilityValue, callback) => {
    let parentElementContainer = document.querySelector(
      '[name="' + element.dataset.visibilityParent + '"]'
    ).parentElement
    radioTrue = parentElementContainer.querySelector('input[value="' + visibilityValue + '"]')
    // radioFalse = parentElementContainer.querySelector('input:not([value="'+visibilityValue+'"])')

    let radioActive = (parentElement, visibilityValue, callback) => {
      parentElementContainer
        .querySelectorAll('input[value="' + visibilityValue + '"]')
        .forEach((radio) => {
          if (radio.checked) {
            callback(true)
          } else {
            callback(false)
          }
        })
    }

    parentElementContainer.addEventListener('change', (e) =>
      radioActive(e.target, visibilityValue, (bool) => setVisibility(element, bool))
    )
    radioActive(radioTrue, visibilityValue, (bool) => setVisibility(element, bool))
  }

  let setVisibility = (element, bool) => {
    if (bool) {
      element.classList.remove('is-hidden')
      element.setAttribute('required', true)
      element.setAttribute('aria-required', true)

      element.querySelectorAll('input.is-required').forEach((el) => {
        el.setAttribute('required', true)
        el.setAttribute('aria-required', true)
      })
    } else {
      element.classList.add('is-hidden')
      element.removeAttribute('required')
      element.removeAttribute('aria-required')

      element.querySelectorAll('input.is-required').forEach((el) => {
        el.removeAttribute('required')
        el.removeAttribute('aria-required')
      })
    }
  }

  init()
})()
