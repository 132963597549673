;((document) => {
  var applyMenu = function () {
    var menuElement = document.querySelector('#menu-slidable')
    var menuButton = document.querySelector('#menu-btn')
    var hideableMainContainer = document.querySelector('.main-container')

    menuButton.addEventListener('click', function () {
      this.classList.toggle('is-active')
      this.getAttribute('aria-expanded') === 'false'
        ? this.setAttribute('aria-expanded', 'true')
        : this.setAttribute('aria-expanded', 'false')
      menuElement.classList.toggle('is-active')
      menuElement.style.top =
        document.getElementById('header-fixed').getBoundingClientRect().height + 'px'

      if (this.classList.contains('is-active')) {
        document.body.classList.add('prevent-scroll')
        hideableMainContainer.setAttribute('aria-hidden', 'true')
      } else {
        document.body.classList.remove('prevent-scroll')
        hideableMainContainer.setAttribute('aria-hidden', 'false')
      }
    })
  }

  if (window.matchMedia('(max-width: 800px)')) applyMenu()
})(document)
