// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('@rails/activestorage').start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require('cms/paragraphs')

require('contact/hide_honeypot')
require('accessibility/mobile_menu')
require('accessibility/dropdown')
require('forms/child_visibility')
require('forms/search_form')
require('forms/tooltips')
require('forms/postcode_lookup')
require('forms/markdown_textarea')

require('stripe/stripe')

require('cookies/prompt')
