import SimpleMDE from 'simplemde'
import axios from 'axios'

;((document) => {
  const PARAGRAPH_CLASS = '.cms-paragraph'

  const paragraphs = document.querySelectorAll(PARAGRAPH_CLASS)

  if (paragraphs.length) {
    let updateParagraph = (url, content) => {
      const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}
      axios.defaults.headers.common['X-CSRF-Token'] = token.content

      axios.patch(url, {
        content: content
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        alert('Failed to save')
      });
    }

    paragraphs.forEach(function(paragraph) {
      let url = '/admin/paragraphs/' + paragraph.getAttribute('data-id')

      var simplemde = new SimpleMDE({
        element: paragraph,
        autoDownloadFontAwesome: false,
        toolbar: [{
            name: "Save",
            action: function customFunction(editor){
              updateParagraph(url, editor.value())
            },
            className: "fa fa-save",
            title: "Save",
          }, "|", "bold", "italic", "heading", "|", "quote", "unordered-list", "ordered-list", "|", "link", "image", "table", "|", "preview", "side-by-side", "fullscreen",
        ],
        status: false
      })
    })
  }
})(document)
