const CARD_ELEMENT_ID = 'card-element'
const STRIPE_PUBLIC_KEY_EL = 'stripe_publishable_key'
const STRIPE_PUBLIC_KEY = document.getElementById(STRIPE_PUBLIC_KEY_EL).value
const STRIPE_FORM_ID = 'payment-form'
const STRIPE_ERROR_EL_ID = 'card-element-errors'

const HIDDEN_CLASS = 'is-hidden'

function displayError(event) {
  // changeLoadingStatePrices(false)
  const displayError = document.getElementById(STRIPE_ERROR_EL_ID)
  const button = document.getElementById('stripe_button')

  if (event.error) {
    button.disabled = true
    displayError.textContent = event.error.message
  } else {
    button.disabled = false
    displayError.textContent = ''
  }
}

if (document.getElementById(STRIPE_FORM_ID)) {
  const form = document.getElementById(STRIPE_FORM_ID)

  form.addEventListener('submit', function (e) {
    e.preventDefault()

    const customerId = document.getElementById('stripe_customer_id').value
    const billingName = document.getElementById('stripe_billing_name').value

    stripe
      .createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          name: billingName,
        },
      })
      .then((result) => {
        if (result.error) {
          displayError(result)
        } else {
          document.getElementById('subscription_stripe_payment_method_id').value =
            result.paymentMethod.id

          document.getElementById('subscription_stripe_payment_method_card_last_4').value =
            result.paymentMethod.card.last4

          document.getElementById('subscription_stripe_payment_method_card_brand').value =
            result.paymentMethod.card.brand

          document.getElementById('subscription_stripe_payment_method_card_exp_month').value =
            result.paymentMethod.card.exp_month

          document.getElementById('subscription_stripe_payment_method_card_exp_year').value =
            result.paymentMethod.card.exp_year

          document.getElementById('payment-step-form').submit()
        }
      })
  })

  const stripe = Stripe(STRIPE_PUBLIC_KEY)
  const elements = stripe.elements()

  const card = elements.create('card', {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#000',
        lineHeight: '40px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '15px',

        '::placeholder': {
          color: '#777',
        },
      },
    },
  })

  card.mount(`#${CARD_ELEMENT_ID}`)

  card.on('change', function (event) {
    displayError(event)
  })
}
