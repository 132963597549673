;((document) => {
  const SEARCH_FORM_ID = 'listing_search_form'
  const SAVE_FORM_ID = 'listing_search_save_form'
  const HIDDEN_CLASS = 'hidden'
  const ARIA_HIDDEN_ATTR = 'aria-hidden'

  const form = document.getElementById(SEARCH_FORM_ID)
  const saveForm = document.getElementById(SAVE_FORM_ID)

  if (form && saveForm) {
    form.addEventListener('input', (event) => {
      saveForm.classList.add(HIDDEN_CLASS)
      saveForm.setAttribute(ARIA_HIDDEN_ATTR, true)
    })
  }
})(document)
