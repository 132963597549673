;((document) => {
  const DROPDOWN_BUTTON = '.dropdown-button'
  const DROPDOWN_CONTENT = '.dropdown-content'

  const buttons = document.querySelectorAll(DROPDOWN_BUTTON)

  let open = function (button) {
    button.setAttribute('aria-expanded', true)
    let content = button.parentElement.querySelector(DROPDOWN_CONTENT)
    content.classList.remove('is-hidden')
    content.focus()
  }

  let close = function (button) {
    button.setAttribute('aria-expanded', false)
    let content = button.parentElement.querySelector(DROPDOWN_CONTENT)
    content.classList.add('is-hidden')
  }

  buttons.forEach(function (button) {
    button.addEventListener('keydown', function (e) {
      // down
      // if (e.keyCode === 40) {
      // up
      // if (e.keyCode === 38) {
      // space
      if (e.keyCode === 32) {
        e.preventDefault
        if (this.getAttribute('aria-expanded') == 'true') {
          close(button)
        } else {
          open(button)
        }
      }
    })

    button.addEventListener('click', function (e) {
      if (this.getAttribute('aria-expanded') == 'true') {
        close(button)
      } else {
        open(button)
      }
    })
  })
})(document)
